import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";
//import { getStorage } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";
//import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

const firebaseConfig = {

  apiKey: "AIzaSyAqG7iipYPIhhaZy-Zxy-GbxXMv_SOCMUw",
  authDomain: "ojt-dcmonorack.firebaseapp.com",
  projectId: "ojt-dcmonorack",
  storageBucket: "ojt-dcmonorack.appspot.com",
  messagingSenderId: "892782685767",
  appId: "1:892782685767:web:f2ceab45e4ecfb14340b62"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
