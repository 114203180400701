import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import {Box, } from '@mui/material';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';


export default function Loading() {
  const classes ={
    mainBox:{
      display: 'flex',
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100vh",
      backgroundColor: 'transparent',
    }
  }
    return (
      <Box sx={classes.mainBox}>
      <Box position="relative" display="inline-flex">
        <CircularProgress sx={{color:"#ad230e"}} />
        <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
           {/* <Typography variant="captions" sx={{color:"#ad230e", fontWeight:700,  fontFamily: 'poppins', }}>
             DC
             </Typography> */}
            <TwoWheelerIcon sx={{color:"#ad230e"}}/>
            
        </Box>
        </Box>
        </Box>
    )
}
